<template>
  <div class="flex flex-1 overflow-auto justify-between mt-2">
    <loader :loading="loading" :backdrop="true" />
    <div class="w-6/12">
      <div class="bg-gray-100 border-gray-400 border p-4">
        <p class="font-bold text-lg mb-4">lease information</p>

        <div class="grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 mb-4">
          <div>
            <p class="text-gray-500 font-bold">status</p>
            <div v-if="occupancyData.current_status">
              <icon
                v-if="occupancyData.current_status == 'Active'"
                name="checkcircle"
                class="inline-block w-3 h-3 mr-1 text-green-400"
              >
              </icon>
              <p class="inline-block text-black">
                {{ occupancyData.current_status }}
              </p>
            </div>
            <p class="text-black" v-else>n/a</p>
          </div>
          <div>
            <p class="text-gray-500 font-bold">unit</p>
            <p class="text-black" v-if="occupancyData.unit">
              {{ occupancyData.unit?.unit_name }}
            </p>
            <p class="text-black" v-else>n/a</p>
          </div>
        </div>
      
        <div class="grid grid-flow-col grid-cols-2 grid-rows-1 gap-5 mb-4">
          <div>
            <p class="text-gray-500 font-bold">leases start date</p>
            <p class="text-black" v-if="occupancyData.start_date">
              {{ formatDateTime(occupancyData.start_date, "L", true) }}
            </p>
            <p class="text-black" v-else>n/a</p>
          </div>

          <div>
            <p class="text-gray-500 font-bold">leases end date</p>
            <p class="text-black" v-if="occupancyData.end_date">
              {{ formatDateTime(occupancyData.end_date, "L", true) }}
            </p>
            <p class="text-black" v-else>n/a</p>
          </div>
        </div>

        <div>
          <p class="text-gray-500 font-bold">move in date</p>
          <p class="text-black" v-if="occupancyData.household_move_in_date">
            {{
              formatDateTime(occupancyData.household_move_in_date, "L", true)
            }}
          </p>
          <p class="text-black" v-else>n/a</p>
        </div>

        <div class="w-full mt-6 mb-2 border-gray-40000 border-t-2">
          <div class="form-row">
            <div class="form-col">
              <label class="mr-2 mt-3" for="rentalPolicyType"
                >rental policy type</label
              >
              <Dropdown
                id="rentalPolicyType"
                :options="rentalPolicyTypeData"
                v-model="rentalPolicyType"
                track-by="value"
              />
              <span v-if="hasError('rentalPolicyType')" class="form-error">{{
                showError("rentalPolicyType")
              }}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-col">
              <label class="mr-2 mt-2" for="rentalPolicyNumber"
                >rental policy number</label
              >
              <input
                type="text"
                class="form-input"
                id="rentalPolicyNumber"
                v-model="rentalInsuranceInfo.policyNumber"
              />
              <span
                v-if="hasError('rentalInsuranceInfo.policyNumber')"
                class="form-error"
                >{{ showError("rentalInsuranceInfo.policyNumber") }}</span
              >
            </div>
            <div class="form-col">
              <label class="mr-2 mt-2" for="insuranceCarrierName"
                >insurance carrier name</label
              >
              <input
                type="text"
                class="form-input"
                id="insuranceCarrierName"
                v-model="rentalInsuranceInfo.carrierName"
              />
              <span
                v-if="hasError('rentalInsuranceInfo.carrierName')"
                class="form-error"
                >{{ showError("rentalInsuranceInfo.carrierName") }}</span
              >
            </div>
          </div>

          <div class="form-row">
            <div class="form-col">
              <label class="mr-2 mt-2" for="rentalPolicyNumber"
                >name of insured</label
              >
              <input
                type="text"
                class="form-input"
                id="nameOfInsured"
                v-model="rentalInsuranceInfo.insuredName"
              />
              <span
                v-if="hasError('rentalInsuranceInfo.insuredName')"
                class="form-error"
                >{{ showError("rentalInsuranceInfo.insuredName") }}</span
              >
            </div>
            <div class="form-col">
              <label class="mr-2 mt-2" for="rentalPolicyStatus"
                >rental policy status</label
              >
              <Dropdown
                id="rentalPolicyStatus"
                :options="rentalPolicyStatusData"
                v-model="rentalPolicyStatus"
                track-by="value"
              />
              <span v-if="hasError('rentalPolicyStatus')" class="form-error">{{
                showError("rentalPolicyStatus")
              }}</span>
            </div>
          </div>

          <div class="form-row">
            <div class="form-col">
            <DateRangePicker
              :opens="'left'"
              :minDate="minDate"
              :autoApply="true"/>
            </div>
          </div>

          <div class="form-row">
            <div class="form-col">
              <p class="text-gray-500 font-bold my-2">
                provide a certificate of insurance (optional)
              </p>
              <AttachmentsField
                class="mt-2"
                with-images
                service="LEASE"
                :max-items="1"
                :accept="['application/pdf']"
                buttonIcon="fileText"
                buttonLabel="add file"
                fileType="file"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section pl-4 pr-4 p-b4 p-2 w-6/12">
      <div class="m-5">
        <div>
          <add-occupant></add-occupant>
        </div>

        <div>
          <toggle-resident
            v-for="(resident, i) in occupancyData.occupancy_relation"
            :key="i"
            :item="resident.party"
            :relationshipType="resident.relationship_type_name"
            :isSelected="clickedItem"
            @selected="handleSelected"
            :index="i"
          >
          </toggle-resident>
        </div>
      </div>
    </div>
    <modal-footer
      :footer="footer"
      :primary="createRentalInsurance"
      :secondary="cancel"
    />
  </div>
</template>

<script>
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";
import ReportsMixin from '@/components/digital_human/ReportMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown';
import DatePicker from '@/components/move_in/DatePicker';
import DateRangePicker from '@/components/move_in/DateRangePicker';
import ToggleResident from '@/components/move_in/ToggleResident'
import AddOccupant from '@/components/move_in/AddOccupant'
import ModalFooter from '@/components/ui/modals/ModalFooter'
import AttachmentsField from '@/components/ui/AttachmentsField';
import AttachmentMixin from '@/mixins/AttachmentMixin';


import Icon from "@/components/ui/Icon";

export default ({
    mixins: [NotifyMixin, AuthMixin, CommunityTimezoneMixin, ModalNavigation, AttachmentMixin, ReportsMixin],
    components: {
        Loader,
        Icon,
        Dropdown,
        DatePicker,
        ToggleResident,
        AddOccupant,
        ModalFooter,
        AttachmentsField,
        DateRangePicker,
    },
    data: function () {
        return {
            loading: false,
            occupancyData: {},
            rentalPolicyType:null,
            rentalPolicyTypeData: [],
            rentalPolicyStatus:null,
            rentalPolicyStatusData: [],
            residents: [
              { id: 1,  name: 'Dan Rogers' },
              { id: 2, name: 'Melissa Rogers' },
            ],

            footer: {
                primaryButton: 'save & next',
                secondaryButton: 'cancel',
            },
            clickedItem: '',
            rentalInsuranceInfo: {
              policyNumber: null,
              carrierName: null,
              insuredName: null,
            },
            minDate: null,
        };
    },
    methods: {
        save(){
            this.$router.push({name: 'leases.costs', id: this.$route.params.id});
        },
        cancel(){
            this.$router.push({name: 'leases.index'});
        },
        handleSelected (id) {
          if (this.clickedItem === id) {
            this.clickedItem = null
          } else {
            this.clickedItem = id
          }
        },
        async loadOccupancy() {
          if (this.$route.params.id) {
              this.loading = true
              await this.$leasingDataProvider.getOne('getOccupancy',{id: this.$route.params.id})
              .then((response) => {
                this.occupancyData = response.data;
                this.loading = false;
              }).catch(() => {
                this.loading = false;
                this.notifyError('There was an error getting the occupancy ');
              });
          }
        },
        async loadRentalPolicyStatusAndType() {
          if (this.$route.params.id) {
              this.loading = true
              await this.$leasingDataProvider.get('getRentalInsuranceStatusAndType')
              .then((response) => {
                response.data?.type.forEach((element, index) => { 
                  this.rentalPolicyTypeData.push({
                    key: index,
                    value: element
                  })
                })
                response.data?.status.forEach((element, index) => { 
                  this.rentalPolicyStatusData.push({
                    key: index,
                    value: element
                  })
                })
                this.loading = false;
              }).catch(() => {
                this.loading = false;
                this.notifyError('There was an error getting the occupancy ');
              });
          }
        },
        async createRentalInsurance() {
          if(this.validator.passes()) {
            if (this.$route.params.id) {
                this.loading = true
                const payload = {}

                payload.customer_id = this.occupancyData.customer_id
                payload.business_unit_id = this.occupancyData.business_id
                payload.rental_insurance_policy_id =  this.occupancyData.occupancy_id
                payload.party_id =  this.occupancyData.occupancy_relation.length > 0 ?
                                    this.occupancyData.occupancy_relation[0].party.party_id : null
                payload.policy_type = this.rentalPolicyType
                payload.policy_number = this.rentalInsuranceInfo.policyNumber
                payload.carrier_name = this.rentalInsuranceInfo.carrierName
                payload.insured_name = this.rentalInsuranceInfo.insuredName
                payload.policy_status = this.rentalPolicyStatus
                payload.policy_expiration = this.endDate
                payload.policy_start = this.startDate
                payload.document_image_uri = this.attachments.length > 0 ? this.attachments[0].id : null,

                await this.$leasingDataProvider.create('createRentalInsurance', {
                  data: payload
                })
                .then(() => {
                  this.notifySuccess('rental policy data saved successfully');
                  this.loading = false;
                  this.$router.push({name: 'leases.costs', id: this.$route.params.id});
                }).catch(() => {
                  this.loading = false;
                  this.notifyError('there was an error saving rental policy ');
                });
            }
          }
        },
        async setDateRange() {
          this.minDate = new Date();
        },
        clearPolicyExpirationDateValue(){
          this.$refs.policyExpirationDate.clearValue()
        },
    },
    validations: {
      'rentalPolicyType' : 'required',
      'rentalInsuranceInfo.policyNumber' : 'required',
      'rentalInsuranceInfo.carrierName' : 'required',
      'rentalInsuranceInfo.insuredName' : 'required',
      'rentalPolicyStatus' : 'required'
    },
    async mounted(){
      this.loadOccupancy();
      this.loadRentalPolicyStatusAndType();

    },
    created(){
       this.setDateRange();
       this.initValidator();
    }

})
</script>

<style scoped>
.color-text {
  color: var(--highlightColor500);
}
.button-color {
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}
</style>
