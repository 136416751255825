<template>
    <div>
        <button
            class="flex items-center font-bold mb-4 focus:outline-none"
            type="button"
            @click="handleClick"
            >
            <icon v-if="toggleSelected" name="chevronDown" class="w-5 h-5 ml-3 " /> 
            <icon v-else name="chevronRight" class="w-5 h-5 ml-3 " /> 
            <p class="ml-1">{{item.full_name}}</p>  <span class="border-gray-400 border-2 px-3 ml-5">{{relationshipType}}</span>
        </button>
        <div class="mx-5 my-5 w-full" v-show="toggleSelected">
             <div class="flex justify-between" v-if="contactInfo">
                <div >
                    <p class="text-gray-500 font-bold">drivers license</p>
                    <p v-if="!contactInfo?.govtIdentity?.length">n/a</p>
                    <p class="text-black" v-for="(contact, i) in contactInfo.govtIdentity" :key=i>{{contact?.unique_identifier}}</p>
                </div>
                 <div>
                    <p class="text-gray-500 font-bold">social security number</p>
                    <p v-if="!contactInfo?.socialSecurityNumber?.length">n/a</p>
                    <p class="text-black" v-for="(contact, i) in contactInfo.socialSecurityNumber" :key=i>****{{contact?.social_security_number}}</p>
                </div>
            </div>
            <div class="flex justify-between my-4">
                <div  
                  v-for="(customTag, i) in customTagsInfo"
                  :key="i">
                    <p class="text-gray-500 font-bold">{{customTag.custom_tag_name}}</p>
                    <icon :name="customTag.custom_tag_icon_uri" class="w-5 h-5 text-green-400" /> 
                </div>
            </div>
            <div class="my-3">
                    <p class="font-bold text-lg">emergency contact</p>
                    <div class="form-row">
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="name">name</label>
                            <input
                            type="text"
                            class="form-input"
                            id="name"
                            />
                        </div>
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="phone">phone</label>
                            <input
                            type="number"
                            class="form-input"
                            id="phone"
                            />
                        </div>
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="relationship">relationship</label>
                            <input
                            type="text"
                            class="form-input"
                            id="relationship"
                            />
                        </div>
                    </div>
            </div>
            <div 
                class="my-5"      
                v-for="(emergency, i) in emergencyInfo"
                :key="i">
                <div class="flex">
                    <button
                        class="text-red-700 focus:outline-none mr-1"
                        type="button"
                    >
                        <icon name="times" class="w-6 h-6" />
                    </button>
                    <p class="font-bold text-lg">emergency contact</p>
                </div>
                <div class="form-row">
                      <div class="form-col">
                          <label class="mr-2 mt-2" for="name">name</label>
                          <input
                          type="text"
                          class="form-input"
                          id="name"
                          v-model="emergencyInfo[i].name"
                          />
                      </div>
                      <div class="form-col">
                          <label class="mr-2 mt-2" for="phone">phone</label>
                          <input
                          type="text"
                          class="form-input"
                          id="phone"
                          v-model="emergencyInfo[i].phone"
                          />
                      </div>
                      <div class="form-col">
                          <label class="mr-2 mt-2" for="relationship">relationship</label>
                          <input
                          type="text"
                          class="form-input"
                          id="relationship"
                          v-model="emergencyInfo[i].relationship"
                          />
                      </div>
                  </div>
            </div>
            <div class="my-3">
                <p class="font-bold text-lg">permission to enter</p>
                <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="name">name</label>
                        <input
                        type="text"
                        class="form-input"
                        id="name"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="phone">phone</label>
                        <input
                        type="number"
                        class="form-input"
                        id="phone"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="relationship">relationship</label>
                        <input
                        type="text"
                        class="form-input"
                        id="relationship"
                        />
                    </div>
                </div>
            </div>
            <div class="my-5">
                <div class="flex justify-between">
                    <p class="font-bold text-lg">pet</p>
                    <button
                        class="flex items-center border-b font-bold focus:outline-none mb-4 button-color "
                        type="button"
                        v-on:click="addPet()"
                        v-if="this.pets.length < 3"
                        >
                        add pet <icon name="plus" class="w-5 h-5 ml-2 color-text" />
                    </button>
                </div>
                 <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="type">type</label>
                        <input
                        type="text"
                        class="form-input"
                        id="type"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="breed">breed (optional)</label>
                        <input
                        type="text"
                        class="form-input"
                        id="breed"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="name">name</label>
                        <input
                        type="text"
                        class="form-input"
                        id="name"
                        />
                    </div>
                </div>
            </div>

            <div 
                class="my-5"      
                v-for="(pet, i) in pets"
                :key="i">
                <div class="flex">
                    <button
                        class="text-red-700 focus:outline-none mr-1"
                        type="button"
                        v-on:click="removePet(i)"
                    >
                        <icon name="times" class="w-6 h-6" />
                    </button>
                    <p class="font-bold text-lg">pet</p>
                </div>
                 <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="type">type</label>
                        <input
                        type="text"
                        class="form-input"
                        id="type"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="breed">breed (optional)</label>
                        <input
                        type="text"
                        class="form-input"
                        id="breed"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="name">name</label>
                        <input
                        type="text"
                        class="form-input"
                        id="name"
                        />
                    </div>
                </div>
            </div>

            <div 
                class="my-5"      
                v-for="(pet, i) in petsInfo"
                :key="i">
                <div class="flex">
                    <button
                        class="text-red-700 focus:outline-none mr-1"
                        type="button"
                        v-on:click="removePet(i)"
                    >
                        <icon name="times" class="w-6 h-6" />
                    </button>
                    <p class="font-bold text-lg">pet</p>
                </div>
                 <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="type">type</label>
                        <input
                        type="text"
                        class="form-input"
                        id="type"
                        v-model="petsInfo[i].pet_species"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="breed">breed (optional)</label>
                        <input
                        type="text"
                        class="form-input"
                        id="breed"
                        v-model="petsInfo[i].pet_breed"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="name">name</label>
                        <input
                        type="text"
                        class="form-input"
                        id="name"
                        v-model="petsInfo[i].pet_name"
                        />
                    </div>
                </div>
            </div>

            <div class="my-5">
                <div class="flex justify-between">
                    <p class="font-bold text-lg">vehicle</p>
                    <button
                        class="flex items-center border-b font-bold focus:outline-none mb-4 button-color "
                        type="button"
                        v-on:click="addCar()"
                        v-if="this.cars.length < 2"
                        >
                        add vehicle <icon name="plus" class="w-5 h-5 ml-2 color-text" />
                    </button>
                </div>
                 <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="make">make</label>
                        <input
                        type="text"
                        class="form-input"
                        id="make"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="model">model</label>
                        <input
                        type="text"
                        class="form-input"
                        id="model"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="color">color</label>
                        <input
                        type="text"
                        class="form-input"
                        id="color"
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="license">license plate</label>
                        <input
                        type="text"
                        class="form-input"
                        id="license"
                        />
                    </div>
                </div>
            </div>

            <div   
                class="my-5"      
                v-for="(car, i) in cars"
                :key="i">
                <div class="flex">
                    <button
                        class="text-red-700 focus:outline-none mr-1"
                        type="button"
                        v-on:click="removeCar(i)"
                    >
                        <icon name="times" class="w-6 h-6" />
                    </button>
                    <p class="font-bold text-lg">vehicle</p>
                   
                </div>
                 <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="make">make</label>
                        <input
                        type="text"
                        class="form-input"
                        id="make"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="model">model</label>
                        <input
                        type="text"
                        class="form-input"
                        id="model"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="color">color</label>
                        <input
                        type="text"
                        class="form-input"
                        id="color"
                        />
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="license">license plate</label>
                        <input
                        type="text"
                        class="form-input"
                        id="license"
                        />
                    </div>
                </div>
            </div>

            <div   
              class="my-5"      
              v-for="(car, i) in vehicleInfo"
              :key="i">
              <div class="flex">
                  <button
                      class="text-red-700 focus:outline-none mr-1"
                      type="button"
                      v-on:click="removeCar(i)"
                  >
                      <icon name="times" class="w-6 h-6" />
                  </button>
                  <p class="font-bold text-lg">vehicle</p>
                  
              </div>
                <div class="form-row">
                  <div class="form-col">
                      <label class="mr-2 mt-2" for="make">make</label>
                      <input
                      type="text"
                      class="form-input"
                      id="make"
                      v-model="vehicleInfo[i].vehicle_make"
                      />
                  </div>
                  <div class="form-col">
                      <label class="mr-2 mt-2" for="model">model</label>
                      <input
                      type="text"
                      class="form-input"
                      id="model"
                      v-model="vehicleInfo[i].vehicle_model"
                      />
                  </div>
                  <div class="form-col">
                      <label class="mr-2 mt-2" for="color">color</label>
                      <input
                      type="text"
                      class="form-input"
                      id="color"
                      v-model="vehicleInfo[i].vehicle_color"
                      />
                  </div>
              </div>
              <div class="form-row">
                  <div class="form-col">
                      <label class="mr-2 mt-2" for="license">license plate</label>
                      <input
                      type="text"
                      class="form-input"
                      id="license"
                      v-model="vehicleInfo[i].vehicle_license_plate_text"
                      />
                  </div>
              </div>
            </div>
            
        </div>
    </div>
</template>


<script>
import Icon from '@/components/ui/Icon'
import AuthMixin from '@/components/auth/AuthMixin'
import NotifyMixin from '@/mixins/NotifyMixin'

export default {
  mixins:[AuthMixin, NotifyMixin],
  components: {
    Icon,
  },
  data () {
    return {
      pets:[],
      petsInfo:[],
      cars:[],
      vehicleInfo:[],
      customTagsInfo:[],
      emergencyInfo:[
        {
          name: 'test name',
          phone: 'test phone',
          relationship: 'test relationship'
        },
      ],
      contactInfo: {},
    }
  },
  computed: {
    toggleSelected () {
      return this.isSelected === this.item.party_id;
    }
  },
  props: {
    index: {
      type: Number, 
      required: true
    },
    item: {
      type: Object,
      required: true
    },
    show: {
      type: Boolean,
      required: true,
      default: false
    },
    isSelected: {
      type: String, 
      required: true
    },
    relationshipType: {
      type: String, 
      required: true,
    }

  },
  methods: {
    handleClick () {
      this.$emit('selected', this.item.party_id)
    },
    addPet() {
      this.pets.push('')
    },
    removePet(i) {
      this.pets.splice(i, 1)
    },
    addCar() {
      this.cars.push('')
    },
    removeCar(i) {
      this.cars.splice(i, 1)
    },
    async loadPets() {
      await this.$leasingDataProvider.get('getPetParty',{party_id: this.item.party_id})      
      .then((response) => {
        this.petsInfo = response.data;
      }).catch(() => {
        this.notifyError('There was an error getting the pets party ');
      });
    },
    async loadVehicles() {
      await this.$leasingDataProvider.get('getVehicleParty',{party_id: this.item.party_id})      
      .then((response) => {
        this.vehicleInfo = response.data;
        this.vehicleInfo.forEach((vehicle, index) => {
          this.vehicleInfo[index].make = vehicle.vehicle_make
          this.vehicleInfo[index].model = vehicle.vehicle_model
          this.vehicleInfo[index].color = vehicle.vehicle_color
          this.vehicleInfo[index].license_plate = vehicle.vehicle_license_plate_text
        })
      }).catch(() => {
        this.notifyError('There was an error getting the vehicles party ');
      });
    },
    async loadCustomTags() {
      await this.$leasingDataProvider.get('getCustomTagParty',{party_id: this.item.party_id})      
      .then((response) => {
        this.customTagsInfo = response.data;
      }).catch(() => {
        this.notifyError('There was an error getting the custom tags party info');
      });
    },
    async loadContactInfo() {
      await this.$contactDataProvider.get('getContactInfo',{contact_id: this.item.contact_id,
      headers: {
        'x-scope-key': this.community.id,
      }})      
      .then((response) => {
        this.contactInfo = response.data;
      }).catch(() => {
        this.notifyError('There was an error getting the custom tags party info');
      });
    },

  },
  mounted(){
    if(this.index == 0) {
      this.$emit('selected', this.item.party_id)
    }
    this.loadPets()
    this.loadVehicles()
    this.loadCustomTags()
    this.loadContactInfo()

  },
  emits: ['selected'],
}
</script>

<style scoped>
.color-text{
  color: var(--highlightColor500);
}
.button-color{
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}

</style>