<template>
  <div>
    <label class="block font-bold mb-3" :for="'date-picker'">select policy start and end date:</label>
    <DatePicker
      :id="'date-picker'"
      class="picker-date"
      ref="datepicker"
      :value="dateRange"
      @change="changeDates"
      @apply="applyDates"
      :format="'MM/DD/YYYY'"
      :autoApply="autoApply"
      :min-date="minDate"
      
      :position="opens"
      range
      multiCalendars
    >
    </DatePicker>
  </div>
</template>

<script>
  import DatePicker from '@/components/ui/datepicker/DatePicker';
  import ReportsMixin from '@/components/digital_human/ReportMixin'
  export default {
    name: "picker-dates",
    components: {DatePicker},
    mixins: [ReportsMixin],
    props: {
      opens: {
        type: String,
        required: true,
      },
      ranges: {
        type: Boolean,
        required: true,
      },
      autoApply: {
        type: Boolean,
        required: true,
      },
      minDate: {
        type: Date,
        required: true,
        default: "1950/01/01",
      },
    },
    data () {
      const currentDate = new Date();
      currentDate.setDate(currentDate.getDate() + 1)
      return {
        dateRange: [
          currentDate.toISOString().slice(0,10),
          currentDate.toISOString().slice(0,10)
        ],
        maxDate: new Date(),
      }
    },
    methods: {
      changeDates(range) {
        const [startDate, endDate] = range || [];

        this.dateRange = [startDate, endDate];

        if (this.autoApply && startDate && endDate) {
          this.setDates({ startDate, endDate });
          // close datepicker
          this.$refs.datepicker.cancel();
        }
      },
      applyDates(range) {
        const [startDate, endDate] = range || [];
        this.setDates({ startDate, endDate });
      },
      setDates(rangeSelected) {
        const start_date = rangeSelected.startDate.toISOString().slice(0,10);
        const end_date = rangeSelected.endDate.toISOString().slice(0,10);

        this.setStartDate(start_date);
        this.setEndDate(end_date);
      }
    },
    mounted() {

    }
  }
</script>

<style >
  .picker-date > .show-calendar {
    margin-top: -3rem;
  }
</style>
