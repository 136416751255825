<template>
  <div>
    <label class="block font-bold mb-3" :for="'date-picker'">{{ label }}</label>
    <DatePicker
      :id="'date-picker'"
      class="picker-date"
      ref="datepicker"
      :value="innerValue"
      @apply="setValue"
      @change="setValue"
      @clear="clearValue"
      :format="'MM/DD/YYYY'"
      :autoApply="autoApply"
      :min-date="minDate"
    >
    </DatePicker>
  </div>
</template>

<script>
import DatePicker from "@/components/ui/datepicker/DatePicker";
import CommunityTimezoneMixin from "@/mixins/CommunityTimezoneMixin";

export default {
  name: "picker-date",
  components: { DatePicker },
  mixins: [CommunityTimezoneMixin],
  props: {
    label: {
      type: String,
      required: true,
      default: "select date:",
    },
    value: {
      type: String,
      required: true,
      default: null,
    },
    autoApply: {
      type: Boolean,
      required: true,
    },
    minDate: {
      type: Date,
      required: true,
      default: "1950/01/01",
    },
  },
  data() {
    return {
      innerValue: null,
    };
  },
  emits: ["input"],
  methods: {
    setValue(date) {
      this.innerValue = date;
      this.$emit("input", date);
    },
    clearValue() {
      this.innerValue = null;
    },
  },
};
</script>

<style>
.picker-date > .show-calendar {
  margin-top: -3rem;
}
</style>