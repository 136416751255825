import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('reports')

export default {
  computed: mapState({
    startDate: (state) => state.startDate,
    endDate: (state) => state.endDate,
  }),
  methods: mapActions([
    'setStartDate',
    'setEndDate',
    'cleanState',
  ]),
}
