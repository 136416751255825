<template>
    <div>
      <div class="flex justify-between">
            <p class="font-bold text-lg mb-4 ">inhabitants</p>
            <button
                class="flex items-center border-b font-bold focus:outline-none mb-4 button-color "
                type="button"
                @click="isHidden = !isHidden"
                >
                add occupant <icon name="plus" class="w-5 h-5 ml-2 color-text" />
            </button>
        </div>

      
     
        <div class=" w-full" v-if="!isHidden">
          <div class=" ">
            <p class="font-bold text-lg mb-2 ">add occupant</p>
          </div>
          <div class="border-gray-40000 border-2 ">
             <div class="form-row m-4">
                <div class="form-col">
                    <label class="mr-2" for="name">first name</label>
                    <input
                    type="text"
                    class="form-input"
                    id="first-name"
                    />
                </div>
                <div class="form-col">
                    <label class="mr-2" for="phone">last name</label>
                    <input
                    type="text"
                    class="form-input"
                    id="last name"
                    />
                </div>
              </div>
              <div class="form-row m-4">
                <div class="form-col">
                    <label class="mr-2 " for="name">driver license</label>
                    <input
                    type="text"
                    class="form-input"
                    id="drive-licenses"
                    />
                </div>
                <div class="form-col mb-4">
                    <label class="mr-2" for="phone">social security number</label>
                    <input
                    type="number"
                    class="form-input"
                    id="social-security-number"
                    />
                </div>
              </div>
            </div>
            <div class="my-3 border-gray-40000 border-2">
                <div class="flex justify-between">
                    <p class="font-bold text-lg m-4">emergency contact</p>
                    <button
                        class="flex items-center border-b font-bold focus:outline-none m-4 button-color "
                        type="button"
                        v-on:click="addEmergencyContact()"
                        v-if="this.emergency_contact.length < 3"
                        >
                        add emergency contact <icon name="plus" class="w-5 h-5 ml-2 color-text" />
                    </button>
                </div>
                <div class="form-row m-4">
                    <div class="form-col mb-4">
                        <label class=" mt-2" for="name">name</label>
                        <input
                        type="text"
                        class="form-input"
                        id="name"
                        />
                    </div>
                    <div class="form-col mb-4">
                        <label class=" mt-2" for="phone">phone</label>
                        <input
                        type="number"
                        class="form-input"
                        id="phone"
                        />
                    </div>
                    <div class="form-col mb-4">
                        <label class=" mt-2" for="relationship">relationship</label>
                        <input
                        type="text"
                        class="form-input"
                        id="relationship"
                        />
                    </div>
                </div>
                <div 
                  class="my-5 m-4"      
                  v-for="(ec, i) in emergency_contact"
                  :key="i">
                  <div class="flex">
                      <button
                          class="text-red-700 focus:outline-none mr-1"
                          type="button"
                          v-on:click="removeEmergencyContact(i)"
                      >
                          <icon name="times" class="w-6 h-6" />
                      </button>
                      <p class="font-bold text-lg">emergency contact</p>
                  </div>
                  <div class="form-row">
                      <div class="form-col mb-4">
                          <label class=" mt-2" for="name">name</label>
                          <input
                          type="text"
                          class="form-input"
                          id="name"
                          />
                      </div>
                      <div class="form-col mb-4">
                          <label class=" mt-2" for="phone">phone</label>
                          <input
                          type="number"
                          class="form-input"
                          id="phone"
                          />
                      </div>
                      <div class="form-col mb-4">
                          <label class=" mt-2" for="relationship">relationship</label>
                          <input
                          type="text"
                          class="form-input"
                          id="relationship"
                          />
                      </div>
                  </div>
                </div>
            </div>
            <div class="my-3 border-gray-40000 border-2">
                <div class="flex justify-between">
                    <p class="font-bold text-lg m-4">permission to enter</p>
                    <button
                        class="flex items-center border-b font-bold focus:outline-none m-4 button-color "
                        type="button"
                        v-on:click="addPermissions()"
                        v-if="this.permissions.length < 3"
                        >
                        add permission to enter <icon name="plus" class="w-5 h-5 ml-2 color-text" />
                    </button>
                </div>
                
                <div class="form-row m-4">
                    <div class="form-col mb-4">
                        <label class=" mt-2" for="name">name</label>
                        <input
                        type="text"
                        class="form-input"
                        id="name"
                        />
                    </div>
                    <div class="form-col">
                        <label class=" mt-2" for="phone">phone</label>
                        <input
                        type="number"
                        class="form-input"
                        id="phone"
                        />
                    </div>
                    <div class="form-col">
                        <label class=" mt-2" for="relationship">relationship</label>
                        <input
                        type="text"
                        class="form-input"
                        id="relationship"
                        />
                    </div>
                </div>
                <div 
                    class="my-5 m-4"      
                    v-for="(permission, i) in permissions"
                    :key="i">
                    <div class="flex">
                        <button
                            class="text-red-700 focus:outline-none mr-1"
                            type="button"
                            v-on:click="removePermissions(i)"
                        >
                            <icon name="times" class="w-6 h-6" />
                        </button>
                        <p class="font-bold text-lg">permission to enter</p>
                    </div>
                    <div class="form-row ">
                      <div class="form-col mb-4">
                          <label class=" mt-2" for="name">name</label>
                          <input
                          type="text"
                          class="form-input"
                          id="name"
                          />
                      </div>
                      <div class="form-col">
                          <label class=" mt-2" for="phone">phone</label>
                          <input
                          type="number"
                          class="form-input"
                          id="phone"
                          />
                      </div>
                      <div class="form-col">
                          <label class=" mt-2" for="relationship">relationship</label>
                          <input
                          type="text"
                          class="form-input"
                          id="relationship"
                          />
                      </div>
                    </div>
                </div>
            </div>
        
            <div class="my-5 border-gray-40000 border-2">
                <div class="flex justify-between">
                    <p class="font-bold text-lg m-4">vehicle</p>
                    <button
                        class="flex items-center border-b font-bold focus:outline-none m-4 button-color "
                        type="button"
                        v-on:click="addCar()"
                        v-if="this.cars.length < 2"
                        >
                        add vehicle <icon name="plus" class="w-5 h-5 ml-2 color-text" />
                    </button>
                </div>
                 <div class="form-row m-4">
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="make">make</label>
                        <input
                        type="text"
                        class="form-input"
                        id="make"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="model">model</label>
                        <input
                        type="text"
                        class="form-input"
                        id="model"
                        />
                    </div>
                    <div class="form-col">
                        <label class="mr-2 mt-2" for="color">color</label>
                        <input
                        type="text"
                        class="form-input"
                        id="color"
                        />
                    </div>
                </div>
                <div class="form-row m-4">
                    <div class="form-col mb-4">
                        <label class="mr-2 mt-2" for="license">license plate</label>
                        <input
                        type="text"
                        class="form-input"
                        id="license"
                        />
                    </div>
                    <div class="form-col mb-4">
                        <label class="mr-2 mt-2" for="parking">parking space</label>
                        <input
                        type="text"
                        class="form-input"
                        id="parking"
                        />
                    </div>
                </div>

                <div   
                class="my-5 m-4"      
                v-for="(car, i) in cars"
                :key="i">
                    <div class="flex">
                        <button
                            class="text-red-700 focus:outline-none mr-1"
                            type="button"
                            v-on:click="removeCar(i)"
                        >
                            <icon name="times" class="w-6 h-6" />
                        </button>
                        <p class="font-bold text-lg">vehicle</p>
                      
                    </div>
                    <div class="form-row">
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="make">make</label>
                            <input
                            type="text"
                            class="form-input"
                            id="make"
                            />
                        </div>
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="model">model</label>
                            <input
                            type="text"
                            class="form-input"
                            id="model"
                            />
                        </div>
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="color">color</label>
                            <input
                            type="text"
                            class="form-input"
                            id="color"
                            />
                        </div>
                    </div>
                    <div class="form-row">
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="license">license plate</label>
                            <input
                            type="text"
                            class="form-input"
                            id="license"
                            />
                        </div>
                        <div class="form-col">
                            <label class="mr-2 mt-2" for="parking">parking space</label>
                            <input
                            type="text"
                            class="form-input"
                            id="parking"
                            />
                        </div>
                    </div>
                </div>

              
            </div>

            <div class="flex justify-between mb-6">
              <button
                type="button"
                class="btn-primary mr-4"
                @click="isHidden = !isHidden"
              >
                cancel
              </button>
              <button
                type="submit"
                class="btn-primary btn-solid"
              >
               save
              </button>
            </div>
            
        </div>
    </div>
</template>


<script>
import Icon from '@/components/ui/Icon'

export default {
  components: {
    Icon,
  },
  data () {
    return {
        isHidden:true,
        emergency_contact:[],
        permissions:[],
        cars:[],
    }
  },
  methods: {
    addEmergencyContact() {
      this.emergency_contact.push('')
    },
    removeEmergencyContact(i) {
      this.emergency_contact.splice(i, 1)
    },
    addPermissions() {
      this.permissions.push('')
    },
    removePermissions(i) {
      this.permissions.splice(i, 1)
    },
    addCar() {
      this.cars.push('')
    },
    removeCar(i) {
      this.cars.splice(i, 1)
    },
  },
}
</script>

<style scoped>
.color-text{
  color: var(--highlightColor500);
}
.button-color{
  border-color: var(--highlightColor500);
  color: var(--highlightColor500);
}

</style>